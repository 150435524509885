import {isApiResponseValid} from "@/helpers/helpers";
import {get, patch, post} from "@/services/api";
import {AppError} from "@/services/errors";
import store from "@/store";

/**
 * Get current user data from localStorage or from api
 * @param {boolean} getFromStorage - if can get data from local storage
 * @return {object} - user data
 */
async function getCurrentUser(getFromStorage = true) {
    try {
        const response = await get('users/me');

        // check response
        if (!isApiResponseValid(response)) {
            throw new AppError('Error loading data', {"message": 'Chyba při načítání uživatele, zkuste obnovit stránku.', 'type': 'warning'})
        }

        // update data
        return response.data;
    } catch (err) {
        if (err instanceof AppError && err.data) {
            store.dispatch('app/addFlashMessage', err.data)
        } else {
            throw err;
        }
    }
}

/**
 * Get all users from api
 * @return {list} - user data
 */
async function getAllUsers() {
    try {
        const response = await get('users/');

        // check response
        if (!isApiResponseValid(response)) {
            throw new AppError('Error loading data', {"message": 'Chyba při načítání uživatelů, zkuste obnovit stránku.', 'type': 'warning'})
        }

        // update data
        return response;
    } catch (err) {
        if (err instanceof AppError && err.data) {
            store.dispatch('app/addFlashMessage', err.data)
        } else {
            throw err;
        }
    }
}

/**
 * Make api call to user login
 * @param {string} firstname - firstname
 * @param {string} lastname - lastname
 * @param {string} email - email
 * @param {string} password - password
 * @param {string} phone - phone
 * @param {boolean} newsletter_subscribed - newsletter
 * @param {boolean} university_student - student
 * @param {string} source - zdroj uživatele (např. digiskills)
 * @return {json} - return response from api
 */
async function createUser(firstname, lastname, email, password, phone = '', newsletter_subscribed = false, university_student = false, source= null) {
    return post('users', {
        firstname,
        lastname,
        phone,
        email,
        password,
        newsletter_subscribed,
        university_student,
        language: 27,
        source
    }, false)
}

/**
 * Make api call to update user data
 * @param {number} userId - userId
 * @param {string} firstname - firstname
 * @param {string} lastname - lastname
 * @param {string} email - email
 * @param {string} phone - phone
 * @param {boolean} university_student - student
 * @param {boolean} contacted - zda byl uživatel kontaktován
 * @param {date} contacted_date - kdy byl uživatel kontaktován
 * @return {json} - return response from api
 */
async function updateUser(userId, firstname, lastname, email, phone = '', university_student = false, contacted = null, contacted_date = null) {
    let data = {
        firstname,
        lastname,
        phone,
        email,
        university_student,
        language: 27
    }
    if (contacted !== null)
        data["contacted"] = contacted
    if (contacted_date !== null)
        data["contacted_date"] = contacted_date
    return patch(`users/${userId}`, data, false)
}

export const userService = {
    getCurrentUser,
    createUser,
    updateUser,
    getAllUsers
};